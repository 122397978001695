import React from 'react';
import logo from './logo.svg';
import './App.css';
import Footer from './Footer';
import MainSection from './MainSection';

function App() {
  return (
    <>
      <MainSection />
      <Footer />
    </>
  );
}

export default App;
